import LoginButton from './LoginButton';
import AddNewWord from './AddNewWord';
import styles from '../styles/Header.module.css'
import { UserDataType } from '../types/UserDataType';
type HeaderProps = {
  addNewWord: React.ReactElement,
  userData: UserDataType
}

export default function Header(props: HeaderProps)
{
  const loggedIn = props.userData.idToken !== '';
  return (
    <>
      <div className={styles.header}>
        <div className={styles.titleBox}>
          <h1 className={styles.title}>
            Learn app
          </h1>
        </div>
        {loggedIn && props.addNewWord}
        {!loggedIn && <LoginButton />}
        {/* <AddNewWord key="addNewWord" /> */}
      </div>
    </>
  )
}
