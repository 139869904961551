'use client';
import { useState } from 'react';
import { apiPath } from '../globals';
import styles from '../styles/AddNewWord.module.css'
import { UserDataType } from '../types/UserDataType';
import { WordType } from '../types/WordType';


type AddNewWordProps = {
    userData: UserDataType
    addNewWord: (word: WordType) => void
}


const defaultWordValue: WordType = {
    word: "",
    description: "",
    id: '-1',
    step: 0,
    created: 0,
    stepChange: 0,
    nextLearn: 0,

}
const addNewWordRest = (idToken: string, data: WordType, setDialogOpen: CallableFunction, setDialogData: CallableFunction, addNewWord: CallableFunction) =>
{
    fetch(apiPath + '/addWord', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        // mode: 'cors', // no-cors, *cors, same-origin
        headers: {
            // 'Content-Type': 'text/plain',
            'Content-Type': 'application/json',
            'Authorization': idToken,
        },
        body: JSON.stringify(data)
    })
        .then(res =>
        {
            if (res.ok)
            {
                return res.json();
            } else
            {
                if (res.status === 401)
                {
                    console.warn("Logintoken invaild");
                    window.localStorage.setItem("idToken", '');
                    window.location.reload()
                } else
                {
                    console.log(res);
                    throw new Error("Error " + res.statusText)
                }
            }
        })
        .then((data: { message: string, success: boolean, newWord: WordType }) =>
        {
            if (data.success)
            {
                setDialogData(defaultWordValue);
                setDialogOpen(false);
                addNewWord(data.newWord)
            }
        })
        // TODO: Error anzeigen?!
        .catch((ex) =>
        {
            console.log(ex);
        })
}

export default function AddNewWord(props: AddNewWordProps)
{

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [dialogData, setDialogData] = useState<WordType>(defaultWordValue);

    const title = 'New word';
    const description = 'Add a new word to your dictionary';
    function onClickSave()
    {
        addNewWordRest(props.userData.idToken, dialogData, setDialogOpen, setDialogData, props.addNewWord);
    }
    function onClickAdd()
    {
        setDialogOpen(true);
    }
    function onClickClose(e: any)
    {
        e.stopPropagation();
        setDialogOpen(false);
        setDialogData(defaultWordValue);

    }
    function dialogClick(e: any)
    {
        if (e.target.id === 'addNewWordDialog')
        {
            console.log("Close Dialog by clicking away")
            e.stopPropagation();
            setDialogOpen(false);
            setDialogData(defaultWordValue);

        }
    }

    return (
        <div className={styles.addNew} onClick={onClickAdd}
            style={{
                cursor: dialogOpen ? '' : 'pointer'
            }}
        >
            <div>
                +
            </div>
            {dialogOpen &&

                <div id="addNewWordDialog" className={styles.modal} style={
                    {
                        display: dialogOpen ? 'block' : 'none'
                    }
                }
                    onClick={dialogClick}>
                    <div className={styles.modalContent}>
                        <div className={styles.modalHeader}>
                            <span className={styles.close} onClick={onClickClose}>&times;</span>
                            <h2>{title}</h2>
                        </div>
                        <div className={styles.modalBody}>
                            <p>{description}</p>
                            <br />
                            <label htmlFor="newWord">New word: </label>
                            <br />
                            <input type="text"
                                id="newWord"
                                name="newWord"
                                autoComplete="off"
                                value={dialogData.word}
                                onChange={(e) => setDialogData({ ...dialogData, word: e.target.value })}
                                className={styles.fieldNewWord + ' ' + styles.textField} />
                            <br />
                            <label htmlFor="wordDescription">Word description: </label>
                            <br />
                            <input type="text"
                                id="wordDescription"
                                name="wordDescription"
                                autoComplete="off"
                                value={dialogData.description}
                                onChange={(e) => setDialogData({ ...dialogData, description: e.target.value })}
                                className={styles.fieldWordDescription + ' ' + styles.textField} />
                        </div>
                        <div className={styles.modalFooter}>
                            <button
                                onClick={onClickSave}
                                className={styles.btnSave + ' ' + styles.footerButton}>
                                Save
                            </button>
                            <button
                                onClick={onClickClose}
                                className={styles.btnCancel + ' ' + styles.footerButton} >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>

    )
}
