import styles from '../styles/WordBox.module.css'
import { WordType } from '../types/WordType';

type ContentProps = {
  wordData: WordType
  setEditId: CallableFunction
}

export default function Content(props: ContentProps)
{
  const word = props.wordData;
  const nextLearn = new Date(word.nextLearn).toLocaleDateString();

  function onClick()
  {
    props.setEditId(word.id)
  }
  return (
    <div key={word.id} className={styles.wordBox} onClick={onClick}>
      <h3>{word.word}</h3>
      <span>{word.description}</span>
      <br />
      <span className={styles.smallLine}>{word.step + " - " + nextLearn}</span>
    </div>
  )
}
