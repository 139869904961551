import AddNewWord from './AddNewWord';
import styles from '../styles/LoginButton.module.css'
import { UserDataType } from '../types/UserDataType';
import { useEffect, useState } from 'react';
type LoginButtonProps = {
}

export default function LoginButton(props: LoginButtonProps)
{
  const [origin, setOrigin] = useState<string>('localhost');

  useEffect(() =>
  {
    setOrigin(window.location.origin);
  }, [])
  function redirectToLogin()
  {
    window.location.href = 'https://lernapp.auth.eu-central-1.amazoncognito.com/login?response_type=token&client_id=5ekopr0m011qnrfd05bamojn9q&redirect_uri=' + origin;
  }
  return (
    <>
      <div onClick={redirectToLogin} className={styles.button}>
        Login
      </div>
    </>
  )
}
