'use client';
import { useEffect, useState } from 'react';
import styles from '../styles/StartTraining.module.css'
import { UserDataType } from '../types/UserDataType';
import { WordDataType } from '../types/WordDataType';
import { WordType } from '../types/WordType';
import { Clear, Check } from '@mui/icons-material';
import { apiPath } from '../globals';

type StartTrainingProps = {
    userData: UserDataType,
    wordData: WordDataType,
    updateWordData: (data: WordDataType) => void
}



export default function StartTraining(props: StartTrainingProps)
{
    const [dialogDisabled, setDialogDisabled] = useState<boolean>(false);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [dialogStep, setDialogStep] = useState<number>(0);
    const [wordsToLearn, setWordsToLearn] = useState<WordType[]>([] as WordType[]);
    const [wordGuesses, setWordGuesses] = useState<string[]>([] as string[])
    const [validationOverwritten, setValidationOverwritten] = useState<boolean[]>([] as boolean[])

    const [windowWidth, setwWindowWidth] = useState<number>(0);


    const notFilled = wordGuesses.indexOf('') !== -1
    useEffect(() =>
    {
        function handleWindowResize()
        {
            setwWindowWidth(window.innerWidth);
        }
        setwWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowResize);

        return () =>
        {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    useEffect(() =>
    {
        var now = new Date();
        var noTimeToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const words = props.wordData.words.filter(w =>
        {
            const date = new Date(w.nextLearn);
            const noTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            return noTime <= noTimeToday
        });
        var takeX = 5;
        if (words.length < 5) takeX = words.length;
        const toLearn = words.slice(0, takeX);
        if (toLearn.length > 0)
        {
            setWordsToLearn(toLearn);
            setWordGuesses(toLearn.map(w => ""))
            setValidationOverwritten(toLearn.map(w => false))
            setDialogDisabled(false);
        } else
        {
            // alert("No words are ready for learning");
            setDialogDisabled(true);
        }

    }, [props.wordData.words, dialogOpen]);


    function generateFieldsSmall(w: WordType, index: number)
    {
        const success = wordsToLearn[index].description.toLowerCase().trim() === wordGuesses[index].toLowerCase().trim();
        return <div key={w.id}>
            <span className={styles.wordToLearn}>
                {w.word}
            </span>
            {/* <label className={styles.wordToLearnHelper} htmlFor="wordDescription">Answer: </label> */}
            <div>
                <input
                    type="text"
                    id="wordDescription"
                    name="wordDescription"
                    autoComplete="off"
                    style={{ backgroundColor: success ? '#55ff553b' : '' }}
                    value={wordGuesses[index]}
                    onChange={(e) =>
                    {
                        var guesses = wordGuesses.map(w =>
                        {
                            return w;
                        });
                        guesses[index] = e.target.value;
                        setWordGuesses(guesses);
                    }}
                    className={styles.fieldWordDescription + ' ' + styles.textField}
                />
            </div>
        </div>
    }
    function generateFieldsTable(w: WordType, index: number)
    {
        const success = wordsToLearn[index].description.toLowerCase().trim() === wordGuesses[index].toLowerCase().trim();
        return <tr key={w.id}>
            <td className={styles.wordToLearn}>
                {w.word}
            </td>
            {/* <td>
                <label className={styles.wordToLearnHelper} htmlFor="wordDescription">Answer: </label>
            </td> */}
            <td>
                <input
                    type="text"
                    id="wordDescription"
                    name="wordDescription"
                    autoComplete="off"
                    style={{ backgroundColor: success ? '#55ff553b' : '' }}
                    value={wordGuesses[index]}
                    onChange={(e) =>
                    {
                        var guesses = wordGuesses.map(w =>
                        {
                            return w;
                        });
                        guesses[index] = e.target.value;
                        setWordGuesses(guesses);
                    }}
                    className={styles.fieldWordDescription + ' ' + styles.textField}
                />
            </td>
        </tr>
    }
    const generateValidator = (word: WordType, index: number) =>
    {
        const success = wordsToLearn[index].description.toLowerCase().trim() === wordGuesses[index].toLowerCase().trim();
        const overwritten = validationOverwritten[index];

        const onChangeValidation = () =>
        {
            var validates = validationOverwritten.map(w =>
            {
                return w;
            });
            validates[index] = !validates[index];
            setValidationOverwritten(validates);
        }
        return <tr key={word.id}>
            <td>
                {word.description} = {wordGuesses[index]}
            </td>
            <td>
                {success || overwritten ? <Check
                    style={{ color: success ? '#55ff55d9' : 'red' }}
                    onClick={onChangeValidation}
                /> : <Clear
                    style={{ color: 'red' }}
                    onClick={onChangeValidation}

                />}
            </td>
        </tr>
    }
    var contentTSX;
    if (dialogStep === 0)
    {
        if (windowWidth < 700)
        {
            contentTSX = wordsToLearn.map(generateFieldsSmall)

        } else
        {
            contentTSX = <table className={styles.detailTable}>
                <tbody>
                    {wordsToLearn.map(generateFieldsTable)}
                </tbody>
            </table>
        }
    } else if (dialogStep === 1)
    {
        contentTSX = <table className={styles.validateTable}>
            <tbody>
                {wordsToLearn.map(generateValidator)}
            </tbody>
        </table>
    }

    const title = 'Word-training';
    const description = 'Train your words';


    function submitTraining(idToken: string, data: any, callback: (changedWords: WordType[]) => void)
    {
        console.log(data);
        fetch(apiPath + '/finishedTraining', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            // mode: 'cors', // no-cors, *cors, same-origin
            headers: {
                // 'Content-Type': 'text/plain',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify(data)
        })
            .then(res =>
            {
                if (res.ok)
                {
                    return res.json();
                } else
                {
                    if (res.status === 401)
                    {
                        console.warn("Logintoken invaild");
                        window.localStorage.setItem("idToken", '');
                        window.location.reload()
                    } else
                    {
                        console.log(res);
                        throw new Error("Error " + res.statusText)
                    }
                }
            })
            .then((data: { message: string, success: boolean, changedWords: WordType[] }) =>
            {
                console.log(data);

                if (data.success)
                {
                    callback(data.changedWords);
                }
            })
            // TODO: Error anzeigen?!
            .catch((ex) =>
            {
                console.log(ex);
            })

    }

    function onClickSave()
    {
        if (dialogStep < 1)
        {
            setDialogStep(dialogStep + 1);
        } else
        {
            var resultWords = wordsToLearn.map((w, index) =>
            {
                const success = wordsToLearn[index].description.toLowerCase().trim() === wordGuesses[index].toLowerCase().trim();
                const overwritten = validationOverwritten[index];
                return {
                    success: success || overwritten,
                    id: w.id
                };
            });
            const result = {
                results: resultWords
            };
            submitTraining(props.userData.idToken, result, (changedWords: WordType[]) =>
            {
                console.log("saved");
                setDialogOpen(false);
                setDialogStep(0);
                const wordData = Object.assign({}, props.wordData);
                changedWords.forEach(w =>
                {
                    const found = wordData.words.find(wo => wo.id === w.id);
                    if (found !== undefined)
                    {
                        found.nextLearn = w.nextLearn;
                        found.step = w.step;
                        found.stepChange = w.stepChange;
                    }
                });
                props.updateWordData(wordData);
            });
        }
        // StartTrainingRest(props.userData.idToken, dialogData, setDialogOpen, setDialogData, props.StartTraining);
    }
    function onClickAdd()
    {
        setDialogOpen(true);
    }
    function onClickClose(e: any)
    {
        e.stopPropagation();
        setDialogOpen(false);

    }
    function dialogClick(e: any)
    {
        if (e.target.id === 'StartTrainingDialog')
        {
            console.log("Close Dialog by clicking away")
            e.stopPropagation();
            setDialogOpen(false);
        }
    }
    if (dialogDisabled)
    {
        return (<div></div>);
    }

    return (
        <div
            className={styles.StartTraining}
            onClick={onClickAdd}
            style={{
                cursor: dialogOpen ? '' : 'pointer'
            }}
        >
            <div>
                Start Training
            </div>
            {dialogOpen &&

                <div id="StartTrainingDialog" className={styles.modal} style={
                    {
                        display: dialogOpen ? 'block' : 'none'
                    }
                }
                    onClick={dialogClick}>
                    <div className={styles.modalContent}>
                        <div className={styles.modalHeader}>
                            <span className={styles.close} onClick={onClickClose}>&times;</span>
                            <h2>{title}</h2>
                        </div>
                        <div className={styles.modalBody}>
                            <p>{description}</p>
                            <br />

                            {contentTSX}

                            <br />

                        </div>
                        <div className={styles.modalFooter}>
                            {/* {notFilled.toString()} */}
                            <button
                                onClick={onClickSave}
                                disabled={notFilled}
                                className={styles.btnSave + ' ' + styles.footerButton}>
                                {dialogStep === 1 ? 'Finish' : 'Next'}
                            </button>
                            <button
                                onClick={onClickClose}
                                className={styles.btnCancel + ' ' + styles.footerButton} >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>

    )
}
