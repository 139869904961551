import { useState } from 'react';
import styles from '../styles/Content.module.css'
import { UserDataType } from '../types/UserDataType';
import { WordDataType } from '../types/WordDataType';
import { WordType } from '../types/WordType';
import EditWordDialog from './EditWordDialog';
import StartTraining from './StartTraining';
import WordBox from './WordBox';

type ContentProps = {
  wordData: WordDataType
  updateWordData: (data: WordDataType) => void
  userData: UserDataType

}

export default function Content(props: ContentProps)
{
  const [editId, setEditId] = useState<string>('');
  const editWord = props.wordData.words.find((w) => w.id === editId);

  const setClose = () =>
  {
    setEditId('');
  }

  const updateEditedWord = (word: WordType) =>
  {
    const IDs = props.wordData.words.map(w => w.id);

    const index = IDs.indexOf(editId);
    const newWordData = { ...props.wordData }
    newWordData.words[index] = word;
    props.updateWordData(newWordData);
  }

  const removeWord = (word: WordType) =>
  {
    const IDs = props.wordData.words.map(w => w.id);

    const index = IDs.indexOf(editId);
    const newWordData = { ...props.wordData }
    newWordData.words.splice(index, 1);
    // newWordData.words[index] = word;
    props.updateWordData(newWordData);
  }
  var words = props.wordData.words;

  var wordBoxes = words.map(
    w =>
      <WordBox
        wordData={w}
        key={w.id}
        setEditId={setEditId}
      />
  );

  return (
    <div className={styles.content}>
      <StartTraining
        userData={props.userData}
        wordData={props.wordData}
        updateWordData={props.updateWordData}
      />
      <h2>Words</h2>
      {wordBoxes}
      {
        editId !== '' &&
        <EditWordDialog
          userData={props.userData}
          setClose={setClose}
          editWord={editWord as WordType}
          updateEditedWord={updateEditedWord}
          removeWord={removeWord}
        />
      }
    </div>
  )
}
