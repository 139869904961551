import styles from './styles/Home.module.css'
import Header from './components/Header';
import Content from './components/Content';
import AddNewWord from './components/AddNewWord';
import { useEffect, useState } from 'react';
import { WordDataType } from './types/WordDataType';
import { UserDataType } from './types/UserDataType';


import './styles/App.css';
import { apiPath } from './globals';
function checkLogin(setUserData: CallableFunction)
{
  if (window.location.hash.length > 5)
  {
    var hashValues = window.location.hash.substr(1).split('&').map((s) =>
    {
      return s.split('=');
    });
    var accessToken = hashValues.find(s => s[0] === "access_token");
    var idToken = hashValues.find(s => s[0] === "id_token");
    if (accessToken && idToken) // New ID TOken
    {
      setUserData({ idToken: idToken[1], accessToken: accessToken[1] })
      window.localStorage.setItem("idToken", idToken[1]);
      window.location.hash = "";
      console.log("Login -> Redirect")
    }
  } else
  { //Loaded IDToken
    const idToken = window.localStorage.getItem("idToken");
    if (idToken !== null && idToken !== '')
    {
      setUserData({ idToken: idToken })
    }
  }
}


function getWords(idToken: string, setWordData: CallableFunction)
{
  fetch(apiPath + '/getWords', {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    // mode: 'cors', // no-cors, *cors, same-origin
    headers: {
      // 'Content-Type': 'text/plain',
      'Content-Type': 'application/json',
      'Authorization': idToken,
    },
    body: JSON.stringify({ wordGroup: 'General' })
  })
    .then(res =>
    {
      if (res.ok)
      {
        return res.json();
      } else
      {
        if (res.status === 401)
        {
          console.warn("Logintoken invaild");
          window.localStorage.setItem("idToken", '');
          window.location.reload()
        } else
        {
          console.log(res);
          throw new Error("Error " + res.statusText)
        }
      }
    })
    .then((data: { success: boolean, wordData: WordDataType }) =>
    {
      if (data.success)
      {
        // setDialogData(defaultWordValue);
        // setDialogOpen(false);
        setWordData(data.wordData);
        window.localStorage.setItem("wordData", JSON.stringify(data.wordData));
      }
    })
    // TODO: Error anzeigen?!
    .catch((ex) =>
    {
      console.log(ex);
    })
}
var loeaded = false;
function App()
{
  const [wordData, setWordData] = useState<WordDataType>({ words: [] });

  const updateWordData = (data: WordDataType) =>
  {
    data.words.sort((a, b) =>
    {
      return a.nextLearn - b.nextLearn;
    });
    setWordData(data);
  }
  const [userData, setUserData] = useState<UserDataType>({ idToken: '', accessToken: '' });


  useEffect(() =>
  {
    if (!loeaded)
    {
      loeaded = true;
      //Check Login
      checkLogin(setUserData);


      const item = localStorage.getItem('wordData');
      if (item !== null)
      {
        const str = item as string;

        updateWordData(
          JSON.parse(str)
        );
      }
    }
  }, [])

  useEffect(() =>
  {
    if (userData.idToken !== '')
    {
      getWords(userData.idToken, updateWordData);
    }
  }, [userData.idToken])

  useEffect(() =>
  {
    const interval = setInterval(() =>
    {
      console.log('This will be called every 3600 seconds');
      if (userData.idToken !== '')
      {
        getWords(userData.idToken, updateWordData);
      }
    }, 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, [userData.idToken]);


  return (
    <>
      <main className={styles.main}>
        <Header userData={userData} addNewWord={<AddNewWord key="addNewWord" userData={userData} addNewWord={(w) =>
        {
          const newData = { ...wordData };
          newData.words.push(w);
          updateWordData(newData)
          window.localStorage.setItem("wordData", JSON.stringify(newData));
        }} />} />
        <Content
          wordData={wordData}
          updateWordData={(data: WordDataType) =>
          {
            updateWordData(data);
            window.localStorage.setItem("wordData", JSON.stringify(data));

          }}
          userData={userData} />
      </main>
    </>
  )
}

export default App;
