'use client';
import { useState } from 'react';
import { apiPath } from '../globals';
import styles from '../styles/EditWordDialog.module.css'
import { UserDataType } from '../types/UserDataType';
import { WordType } from '../types/WordType';


type EditWordDialogProps = {
    userData: UserDataType
    editWord: WordType
    setClose: () => void
    updateEditedWord: (word: WordType) => void
    removeWord: (word: WordType) => void
    // addeditWord: (word: WordType) => void
}


const sendEditWord = (idToken: string, data: WordType, setClosed: CallableFunction, updateEditedWord: (word: WordType) => void) =>
{
    fetch(apiPath + '/editWord', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        // mode: 'cors', // no-cors, *cors, same-origin
        headers: {
            // 'Content-Type': 'text/plain',
            'Content-Type': 'application/json',
            'Authorization': idToken,
        },
        body: JSON.stringify(data)
    })
        .then(res =>
        {
            if (res.ok)
            {
                return res.json();
            } else
            {
                if (res.status === 401)
                {
                    console.warn("Logintoken invaild");
                    window.localStorage.setItem("idToken", '');
                    window.location.reload()
                } else
                {
                    console.log(res);
                    throw new Error("Error " + res.statusText)
                }
            }
        })
        .then((data: { message: string, success: boolean, newWord: WordType }) =>
        {
            console.log(data);

            if (data.success)
            {
                updateEditedWord(data.newWord);
                setClosed();
            }
        })
        // TODO: Error anzeigen?!
        .catch((ex) =>
        {
            console.log(ex);
        })
}


const sendRemoveWord = (idToken: string, data: WordType, setClosed: CallableFunction, updateRemovedWord: (word: WordType) => void) =>
{
    fetch(apiPath + '/removeWord', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        // mode: 'cors', // no-cors, *cors, same-origin
        headers: {
            // 'Content-Type': 'text/plain',
            'Content-Type': 'application/json',
            'Authorization': idToken,
        },
        body: JSON.stringify(data)
    })
        .then(res =>
        {
            if (res.ok)
            {
                return res.json();
            } else
            {
                if (res.status === 401)
                {
                    console.warn("Logintoken invaild");
                    window.localStorage.setItem("idToken", '');
                    window.location.reload()
                } else
                {
                    console.log(res);
                    throw new Error("Error " + res.statusText)
                }
            }
        })
        .then((data: { message: string, success: boolean, newWord: WordType }) =>
        {
            console.log(data);

            if (data.success)
            {
                updateRemovedWord(data.newWord);
                setClosed();
            }
        })
        // TODO: Error anzeigen?!
        .catch((ex) =>
        {
            console.log(ex);
        })
}
export default function EditWordDialog(props: EditWordDialogProps)
{

    const [dialogData, setDialogData] = useState<WordType>(props.editWord);

    const title = 'Manage saved word';
    const description = 'Change or delete a word in/from your dictionary';
    function onClickSave()
    {
        sendEditWord(props.userData.idToken, dialogData, props.setClose, props.updateEditedWord);
    }
    function onClickRemove()
    {
        sendRemoveWord(props.userData.idToken, dialogData, props.setClose, props.removeWord);
    }
    function onClickClose(e: any)
    {
        e.stopPropagation();
        props.setClose();
    }
    function dialogClick(e: any)
    {
        if (e.target.id === 'editWordDialog')
        {
            console.log("Close Dialog by clicking away")
            e.stopPropagation();
            props.setClose();
        }
    }

    return (
        <div className={styles.addNew}        >
            {
                <div id="editWordDialog" className={styles.modal}
                    onClick={dialogClick}>
                    <div className={styles.modalContent}>
                        <div className={styles.modalHeader}>
                            <span className={styles.close} onClick={onClickClose}>&times;</span>
                            <h2>{title}</h2>
                        </div>
                        <div className={styles.modalBody}>
                            <p>{description}</p>
                            <br />
                            <label htmlFor="editWord">Word: </label>
                            <br />
                            <input type="text"
                                id="editWord"
                                name="editWord"
                                autoComplete="off"
                                value={dialogData.word}
                                onChange={(e) => setDialogData({ ...dialogData, word: e.target.value })}
                                className={styles.fieldeditWord + ' ' + styles.textField} />
                            <br />
                            <label htmlFor="wordDescription">Word description: </label>
                            <br />
                            <input type="text"
                                id="wordDescription"
                                name="wordDescription"
                                autoComplete="off"
                                value={dialogData.description}
                                onChange={(e) => setDialogData({ ...dialogData, description: e.target.value })}
                                className={styles.fieldWordDescription + ' ' + styles.textField} />
                        </div>
                        <div className={styles.modalFooter}>
                            <button
                                onClick={onClickSave}
                                className={styles.btnSave + ' ' + styles.footerButton}>
                                Save
                            </button>
                            <div className={styles.btnRight}>
                                <button
                                    onClick={onClickRemove}
                                    className={styles.btnRemove + ' ' + styles.footerButton} >
                                    Delete
                                </button>
                                <button
                                    onClick={onClickClose}
                                    className={styles.btnCancel + ' ' + styles.footerButton} >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>

    )
}
